@import './variables.less';

.singleLineText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.multiLineText {
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.autoContent {
  margin: 0 auto;
}

.arrow {
  &::after {
    width: 7px;
    height: 7px;
    display: inline-block;
    content: ' ';
    border: 1px solid @textColor6;
    border-top-color: transparent;
    border-left-color: transparent;
    transform-origin: center;
    transform: rotate(-45deg) translateY(-4px);
    transition: transform .24s;
  }
}

.arrowDown {
  &::after {
    transform: rotate(45deg) translateY(-4px);
    transition: transform .24s;
  }
}

.arrowUp {
  &::after {
    transform: rotate(225deg);
    transition: transform .24s;
  }
}

.formWrapper {
  margin-bottom: 0;
}

.tipsFormItem {
  width: 48%;
  display: inline-block;
  vertical-align: initial;
  margin-right: @margin-xs;
}

.tipsIcon {
  margin-right: @margin-xss;
}

.tipsText {
  color: @normal-color;
  margin-left: @margin-xs;
}

.uploadTipsText {
  color: @normal-color;
}

.row {
  flex-flow: row;
  flex-wrap: nowrap !important;
  min-height: 90%;
}

.hoverCard {
  transition: @transition-base;
  cursor: pointer;

  &:hover {
    transform: translateY(-8px);
  }
}

.alert {
  :global {
    .ant-alert-message {
      color: @textColor2;
    }
  }
}

.popconfirm {
  padding: @padding-sm @padding-md;
  width: calc(100% + 30px);

  :global {
    .ant-dropdown-menu-message {
      position: relative;
    }
    .ant-dropdown-menu-message-title {
      display: inline-block;
      padding: @padding-xss 0 @padding-sm 22px;
    }
    .ant-dropdown-menu-message > .anticon {
      position: absolute;
      top: 8.0005px;
      color: #faad14;
      font-size: 14px;
    }
    .ant-dropdown-menu-buttons {
      text-align: right;
    }
    .ant-dropdown-menu-buttons  button {
      margin-left: 8px !important;
    }
  }
}

.beforeContent {
  &::before {
    content: ' ';
    display: block;
  }
}

.afterContent {
  &::after {
    content: ' ';
    display: block;
  }
}
